import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

import { environment } from '../../../environments/environment';
import * as AWS from 'aws-sdk';

@Component({
  selector: 'app-slideshow-display',
  templateUrl: './slideshow-display.component.html',
  styleUrls: ['./slideshow-display.component.scss']
})
export class SlideshowDisplayComponent implements OnInit {

  public photos = [];
  public loading = true;

  private baseUrl: any;
  private bucketUrl: string;
  private queryParams: any;

  private loadingTimeout: 2000;

  private menuOnlyRegex = '(\\bmenu[/][A-za-z0-9])';
  private posterOnlyRegex = '(\\bposters[/][A-za-z0-9])';

  constructor(
    private httpClient: HttpClient,
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe(params => {
      this.queryParams = params;
      console.log(params);
    });
  }

  ngOnInit(): void {

    const S3 = new AWS.S3({
      accessKeyId: environment.S3_ACCESS_KEY,
      secretAccessKey: environment.S3_SECRET_ACCESS_KEY,
      region: 'ap-southeast-2',
    });

    const params = {
      Bucket: environment.S3_BUCKET_NAME
    };

    S3.listObjects(params, async (err, data) => {

      if (err) {
        console.log(err);
        this.loading = false;
        // TODO ERROR

        return;
      }

      this.baseUrl = S3.getBucketLocation();
      this.bucketUrl = this.baseUrl.httpRequest.endpoint.href + 'hell-tv-display/';

      data?.Contents.map((photo) => {

        // Menu only if requested, default to posters
        if (this.queryParams?.menu) {
          if (photo?.Key.match(this.menuOnlyRegex)) {
            this.photos.push(this.bucketUrl + encodeURIComponent(photo.Key));
          }
        } else {
          if (photo?.Key.match(this.posterOnlyRegex)) {
            this.photos.push(this.bucketUrl + encodeURIComponent(photo.Key));
          }
        }

      });


    });
  }

  startSlideshow(): void {

    const images = document.getElementsByClassName('poster-image') as HTMLCollectionOf<HTMLElement>;

    let current = 0;
    setInterval(() => {
      for (let i = 0; i < images.length; i++) {
        images[i].style.opacity = '0';
      }

      current = (current !== images.length - 1) ? current + 1 : 0;
      images[current].style.opacity = '1';

    }, 30000);
  }

  imageLoaded(index) {

    const element = document.getElementById(index + "");
    element['loaded'] = true;

    const images = document.getElementsByClassName('poster-image') as HTMLCollectionOf<HTMLElement>;

    console.log('Loaded ' + index + ' out of ' + images.length);

    let count = 0;
    for (let i = 0; i < images.length; i++) {
      if (images[i]['loaded']) {
        count++;
      }
    }

    if (count === images.length) {

      const rotate = this.queryParams?.rotate;

      // Rotate the photos if asked for
      for (let i = 0; i < images.length; i++) {
        if (rotate === '90') {
          images[i].classList.add('rotate-90');
        } else if (rotate === '270') {
          images[i].classList.add('rotate-270');
        } else {
          images[i].classList.add('rotate-0');
        }
      }

      console.log("All images loaded");

      if (this.queryParams?.menu && (this.queryParams?.left || this.queryParams?.right)) {
        const images = document.getElementsByClassName('poster-image') as HTMLCollectionOf<HTMLElement>;
        console.log(images);
        images[0].style.opacity = this.queryParams?.left ? '1' : '0';
        images[1].style.opacity = this.queryParams?.right ? '1' : '0';
      } else {
        this.startSlideshow();
      }

      this.loading = false;

    }

  }

}
