import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SlideshowDisplayComponent } from './components/slideshow-display/slideshow-display.component';

const routes: Routes = [
  {
    path: '', component: SlideshowDisplayComponent, pathMatch: 'full',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
