<div class="page" id="menu-loader" *ngIf="loading">
  <div class="loader-container">
    <img src="/assets/loader.gif" alt="" class="loader">
  </div>
</div>

<div class="page" [hidden]="loading">
  <div class="slideshow" id="slideshow">
    <img id="{{i}}" *ngFor="let photo of photos; let i = index" src="{{photo}}" alt="" class="poster-image"  (load)="imageLoaded(i)">
  </div>
</div>

